import { RemixBrowser } from "@remix-run/react";
import i18next from "i18next";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import Fetch from "i18next-fetch-backend";
import { StrictMode, startTransition } from "react";
import { I18nProvider } from "react-aria-components";
import { hydrateRoot } from "react-dom/client";
import { I18nextProvider, initReactI18next } from "react-i18next";
import { getInitialNamespaces } from "remix-i18next/client";
import { defaultNS, fallbackLng, supportedLngs } from "~/config/i18n";

async function main() {
	await i18next
		.use(initReactI18next)
		.use(Fetch)
		.use(I18nextBrowserLanguageDetector)
		.init({
			fallbackLng,
			supportedLngs,
			defaultNS,
			ns: getInitialNamespaces(),
			detection: { order: ["htmlTag"], caches: [] },
			backend: {
				loadPath: "/api/locales?lng={{lng}}&ns={{ns}}",
			},
		});

	startTransition(() => {
		hydrateRoot(
			document,
			<I18nextProvider i18n={i18next}>
				<I18nProvider locale={i18next.language}>
					<StrictMode>
						<RemixBrowser />
					</StrictMode>
				</I18nProvider>
			</I18nextProvider>,
		);
	});
}

main().catch((error) => console.error(error));
